import React from 'react'
import { graphql } from 'gatsby'
import { RichText, Elements } from 'prismic-reactjs'
import { convertStringToId } from '../utils'
import * as Component from '../components'

const listSerializer = (type, element, content, children, key) => {
  if (type !== Elements.heading2) {
    return null
  }

  return React.createElement('a', { key, href: `#${convertStringToId(element.text)}` }, children)
}

const titleSerializer = (type, element, content, children, key) => {
  if (type !== Elements.heading2) {
    return null
  }

  return React.createElement('h2', { key, id: convertStringToId(element.text) }, children)
}

const htmlSerializer = (type, element, content, children, key) => {
  switch (type) {
    case Elements.preformatted:
      return React.createElement('pre', { className: 'prettyprint', key }, children)
    case Elements.heading1:
      return React.createElement('h1', { key, id: convertStringToId(element.text, true) }, children)
    case Elements.heading2:
      return React.createElement('h2', { key, id: convertStringToId(element.text, true) }, children)
    case Elements.heading3:
      return React.createElement('h3', { key, id: convertStringToId(element.text, true) }, children)
    case Elements.heading4:
      return React.createElement('h4', { key, id: convertStringToId(element.text, true) }, children)
    case Elements.heading5:
      return React.createElement('h5', { key, id: convertStringToId(element.text, true) }, children)
    case Elements.heading6:
      return React.createElement('h6', { key, id: convertStringToId(element.text, true) }, children)

    default:
      return null
  }
}

export default ({ data }) => {
  if (!data) return 'loading...'

  const { document } = data.prismic
  if (!document) return <Component.NotFound />

  return (
    <>
      <Component.Schema
        markup={{
          "@type": "Article",
          "headline": RichText.asText(document.title),
          "name": RichText.asText(document.title),
          "datePublished": document._meta.firstPublicationDate,
          "dateModified": document._meta.lastPublicationDate,
          "articleBody": RichText.asText(document.description),
          "description": RichText.asText(document.description),
          "url": `https://hesambayat.com/doc/${document._meta.uid}`,
          "image": `https://hesambayat.com${document.imageSharp.publicURL}`
        }} />
      <Component.Header />
      <div className="document">
        <div className="container">
          <div className="alignwide">
            <div className="row">
              <div className="col-12 col-lg-3">
                <div className="contents">
                  {
                    document.section.map((slice, idx) => {
                      return (
                        <RichText key={`list-${idx}`} render={slice.sectiontitle} htmlSerializer={listSerializer} />
                      )
                    })
                  }
                </div>
              </div>
              <div className="col-12 col-lg-9">
                <header>
                  <RichText render={document.title} />
                  <RichText render={document.description} />
                </header>
                {
                  document.section.map((slice, idx) => {
                    return (
                      <div className="section" key={`section-${idx}`}>
                        <RichText render={slice.sectiontitle} htmlSerializer={titleSerializer} />
                        <RichText render={slice.sectionbody} htmlSerializer={htmlSerializer} />
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Component.Footer />
      <Component.CodePrettify />
    </>
  )
}

export const query = graphql`
  query DocumentQuery($uid: String!) {
    prismic {
      document(lang:"en-us", uid: $uid) {
        title
        description
        image
        imageSharp {
          publicURL
        }
        section {
          ... on PRISMIC_DocumentSection {
            sectionbody
            sectiontitle
          }
        }
        _meta {
          uid
          tags
          firstPublicationDate
          lastPublicationDate
        }
      }
    }
  }
`